import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/layout';
import { DrugHistory } from './components/drughistory';
import { DrugSearch } from './components/drugsearch';
import { SearchDetail } from './components/searchdetail';

const BlueTheme = React.lazy(() => import('./themes/bluetheme'));

const ThemeSelector = ({ children }) => {
    return (
        <>
            <React.Suspense fallback={
                <div className="wrapper">
                    <div className="content-wrapper" style={{ "minHeight": "75vh", "background": "#fff" }}>
                        <div className="text-center">
                            <h3>&nbsp;</h3>
                            <h3>Loading...</h3>
                        </div>
                    </div>
                </div>}>
                {(window.tenant.settings.themeColor === "blue") && <BlueTheme />}
                {children}
            </React.Suspense>
        </>
    )
}
export default class App extends Component {
    static displayName = App.name;

    componentWillMount() {
    }

    render() {
        return (
            <ThemeSelector>
                <Layout>
                    <Route exact path='/' component={DrugSearch} />
                    <Route path='/drughistory' component={DrugHistory} />
                    <Route path='/searchdetail' component={SearchDetail} />
                </Layout>
            </ThemeSelector>
        );
    }
}
