import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select'
import { appConfig } from '../config';
import Loader from './shared/loader'

export class Filters extends PureComponent {
    static propTypes = {
        onApplyFilters: PropTypes.func
    }

    constructor(props) {
        super(props);

        if (props.filters) {
            this.state = props.filters;
            this.state.loading = false;
        }
        else {
            this.state = {
                useMyLocation: false,
                address: '',
                location: '',
                locationMessage: '',
                filters: [],
                loading: false,
            };
        }

        this.applyFilters = this.applyFilters.bind(this);
    }

    getAvailableFilters() {
        return [
            { value: 'deliveryService', label: 'Home Delivery' },
            { value: 'driveUpWindow', label: 'Drive-Thru Pharmacy' },
            { value: 'walkInClinic', label: 'Clinical staff available' },
            { value: 'immunizationsProvided', label: 'Immunization Clinic' },
            { value: 'open24Hours', label: 'Open 24 hours' }
        ];
    }

    applyFilters() {
        this.setState({ loading: true });

        if (this.state.useMyLocation) {
            this.getBrowserLocation().then(() => {
                this.setState({ loading: false });
                this.props.onApplyFilters(this.state);
            }, () => { this.setState({ loading: false }); })
        }
        else if (this.state.address) {
            this.getGeoLocation().then(() => {
                this.setState({ loading: false });
                this.props.onApplyFilters(this.state);
            }, () => { this.setState({ loading: false }); })
        }
        else {
            this.state.location = null;
            this.setState({ location: null, loading: false });
            this.props.onApplyFilters(this.state);
        }
    }

    getBrowserLocation() {
        var self = this;
        return new Promise((resolve, reject) => {
            if (this.state.useMyLocation) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        self.setState({ location: { lat: position.coords.latitude, lng: position.coords.longitude } });
                        resolve();
                    });
                } else {
                    self.setState({ locationMessage: "Geolocation is not supported by this browser." });
                    reject();
                }
            }
        })
    }

    getGeoLocation() {
        return new Promise((resolve, reject) => {
            var self = this;
            var url = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.state.address}&key=${appConfig.gmapKey}`;
            fetch(url)
                .then(this.errorHandler)
                .then(response => {
                    if (response.status == 200)
                        return response.json()

                    return null;
                })
                .then(data => {
                    if (data && data.results && data.results.length) {
                        var location = data.results[0].geometry.location;
                        var postalcode = data.results[0].address_components.find(a => a.types.findIndex(x => x == 'postal_code') >= 0);
                        location.zip = postalcode.long_name;

                        self.setState({ location: location });
                        resolve();
                    }
                    else {
                        self.setState({ locationMessage: 'Location is invalid' });
                        reject();
                    }
                });
        });
    }

    render() {
        const { useMyLocation, address, locationMessage, filters, loading } = this.state;
        return (
            <div className="filters">
                <div className="form-group">
                    <label>Location</label>
                    <input className="form-control"
                        value={address}
                        onChange={(e) => { this.setState({ address: e.target.value }) }} />
                    {locationMessage ? <div className="invalid-feedback d-block">{locationMessage}</div> : ''}
                    <div className="margin-top-10">
                        <input type="checkbox"
                            id="my-location"
                            checked={useMyLocation}
                            onChange={(e) => { this.setState({ useMyLocation: e.target.checked }) }} />
                        <label htmlFor="my-location">Use my location</label>
                    </div>
                </div>
                <label>Filters</label>
                <div className="form-inline">
                    <Select
                        className="select"
                        classNamePrefix="select"
                        options={this.getAvailableFilters()}
                        value={filters}
                        isMulti={true}
                        placeholder="Choose filters"
                        isSearchable={false}
                        onChange={(op) => this.setState({ filters: op })}
                    />

                    {loading ?
                        <button className="btn btn-default"><Loader color="#fff" size="1x" /></button>
                        :
                        <button className="btn btn-default" onClick={(e) => this.applyFilters()}>Apply</button>
                    }
                </div>
            </div>
        )
    }
}