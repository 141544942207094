import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchBar } from './shared/searchbar'
export class DrugSearch extends Component {
    static displayName = DrugSearch.name;

    constructor() {
        super();
        this.state = {
            searchText: '',
        }

        this.goToProduct = this.goToProduct.bind(this);
    }

    goToProduct(option) {
        if (option) {
            this.props.history.push({
                pathname: '/searchdetail',
                state: { drugName: option.value }
            });
        }
    }

    render() {
        const { searchText } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12" style={{ marginTop: "15vh" }}>
                        <h2 className="text-center">Find Drugs & Pharmacy Near you</h2>
                    </div>
                </div>
                <div className="row justify-content-center margin-bottom-10">
                    <div className="col-md-8 text-center">
                        <h5>
                            Find the major national chains as well as local <br />
                            independent pharmacies.</h5>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                        <div className="form-group">
                            <div className="input-group">
                                <SearchBar onSelect={this.goToProduct} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                        <span> <span className="font-weight-light"> Trending searches: </span><b> gabapentin, amlodipine, lisinopril, prednisone, hydroxychloroquine</b></span>
                    </div>
                </div>

            </div>
        );
    }
}
