import React, { Component } from 'react';
import { SearchBar } from './shared/searchbar'
import { Map } from './map'
import Loader from './shared/loader'
import Select from 'react-select'
import { components } from 'react-select'
import { faFilter, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Filters } from './filters';
import { appConfig } from '../config';
import NumberFormat from 'react-number-format';

export class SearchDetail extends Component {
    constructor(props) {
        super(props);

        this.goToProduct = this.goToProduct.bind(this);
        this.changeDrugType = this.changeDrugType.bind(this);
        this.getAvailableForms = this.getAvailableForms.bind(this);
        this.getAvailableStrengths = this.getAvailableStrengths.bind(this);
        this.getAvailableQuantities = this.getAvailableQuantities.bind(this);
        this.loadMoreResults = this.loadMoreResults.bind(this);
        this.getCoupon = this.getCoupon.bind(this);
        this.displayResults = this.displayResults.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.filterResults = this.filterResults.bind(this);
    }
    state = {
        drugName: '',
        drugType: '',
        form: '',
        isLoading: false,
        strength: '',
        quantity: '',
        showFilters: false,
        hasMoreResults: false,
        listCount: 5,
        member: {
            primaryAddress: null
        },
        product: {
            pharmacies: [],
            traits: [],
            selectedTraits: []
        },
        deliveryOptions: [],
        selectedDeliveryOption: 1,
        coupon: null,
        couponPrice: null,
        results: [],
        filters: null,
    }

    componentWillMount() {
        if (this.props.location.state && this.props.location.state.drugName) {
            this.state.drugName = this.props.location.state.drugName;
        }
    }

    componentDidMount() {
        this.getMemberDetail();
        this.getProductDetail(this.state.drugName, false, true);
    }

    getMemberDetail() {
        var url = '/api/members/01?groupOrgId=2887&membershipId=9997013';
        fetch(url)
            .then(this.errorHandler)
            .then(response => {
                if (response.status == 200)
                    return response.json()

                return null;
            })
            .then(data => {
                this.setState({ member: data });
            });
    }

    getProductDetail(drugName, refine, refresh) {
        this.setState({ isLoading: true });

        var url = `/api/products/${drugName}`;
        var query = '';
        if (refine) {
            query += `&form=${this.state.form ? this.state.form.value : ''}` +
                `&strength=${this.state.strength ? this.state.strength.value : ''}` +
                `&quantity=${this.state.quantity ? this.state.quantity.value : ''}`;
        }

        var filters = this.state.filters;
        if (filters && filters.location) {
            query += `&latitude=${filters.location.lat}` +
                `&longitude=${filters.location.lng}`
        }

        if (query) {
            url += '?' + query.slice(1, query.length);
        }

        fetch(url)
            .then(this.errorHandler)
            .then(response => {
                if (response.status == 200)
                    return response.json()

                return null;
            })
            .then(data => {

                if (data) {
                    this.setState({ product: data });

                    var deliveryOptions = [{ value: 1, label: "Local" }];

                    if (data.mailOrderResults && data.mailOrderResults.length) {
                        deliveryOptions.push({ value: 2, label: "Mail-Order" });
                    }

                    if (data.specialtyResults && data.specialtyResults.length) {
                        deliveryOptions.push({ value: 3, label: "Specialty" });
                    }

                    // set selected traits
                    this.setState({
                        drugName: data.selectedTraits.name,
                        //markers: markers,
                        hasMoreResults: data.pharmacies.length > this.state.listCount,
                        form: { value: data.selectedTraits.formCode, label: data.selectedTraits.formDesc },
                        strength: { value: data.selectedTraits.strength, label: data.selectedTraits.strength },
                        quantity: { value: data.selectedTraits.quantity, label: data.selectedTraits.quantity },
                        drugType: { value: data.selectedTraits.name, label: data.selectedTraits.name + ` (${data.selectedTraits.brand ? "Brand" : "Generic"})`, type: (data.selectedTraits.brand ? "Brand" : "Generic") },
                        deliveryOptions: deliveryOptions,
                        selectedDeliveryOption: 1,
                        listCount: 5
                    });

                    this.filterResults(1/*local*/);

                    if (refresh || refine) {
                        this.getCoupon();
                    }
                    else if(this.state.coupon) {
                        this.setCouponPrice(this.state.coupon);
                    }
                }

                this.setState({ isLoading: false });
            });
    }

    getCoupon() {
        if (this.state.product && this.state.product.selectedTraits) {
            var prevCoupon = this.state.coupon;
            this.setState({ coupon: null, couponPrice: null });
            var selectedTraits = this.state.product.selectedTraits;
            var ndc = selectedTraits.ndcs[0];
            var productName = this.state.product.name + ' ' + selectedTraits.formDesc; //+ ' ' + selectedTraits.strength;
            var url = `/api/coupons/search?ndc=${ndc}&productname=${productName}`;

            fetch(url)
                .then(this.errorHandler)
                .then(response => {
                    if (response.status == 200)
                        return response.json()
                    if (response.status == 204)
                        return prevCoupon;

                    return null;
                })
                .then(data => {
                    if (data) {
                        this.setState({ coupon: data });
                        this.setCouponPrice(data);
                    }
                });
        }
    }

    setCouponPrice(coupon) {
        if (this.state.product && this.state.product.selectedTraits) {
            var memberPrice = 0;

            var pharmacies = this.state.product && this.state.product.pharmacies;
            if (pharmacies && pharmacies.length) {
                memberPrice = pharmacies[0].memberCopay;
            }

            var lowestPrice = null;
            if (coupon.firstFillMaximumBenefitAllocation) {
                lowestPrice = memberPrice - coupon.firstFillMaximumBenefitAllocation;

                if (lowestPrice < coupon.residualCoPayAfterCardBenefits) {
                    lowestPrice = coupon.residualCoPayAfterCardBenefits;
                }
                else if (lowestPrice < 0) {
                    lowestPrice = 0;
                }
            }
            else if (coupon.residualCoPayAfterCardBenefits) {
                lowestPrice = coupon.residualCoPayAfterCardBenefits;
            }

            this.setState({ couponPrice: lowestPrice });
        }
    }
    errorHandler(response) {
        if (response.status != 200
            && response.status != 204) {
            console.error(response);
        }
        return response;
    }

    goToProduct(option) {
        if (option) {
            this.setState({ drugName: option.value });
            this.getProductDetail(option.value, false, true);
        }
    }

    loadMoreResults() {
        if (this.state.product
            && this.state.product.pharmacies.length > this.state.listCount) {
            this.setState({ hasMoreResults: true, listCount: this.state.listCount + 10 });
        }
        else {
            this.setState({ hasMoreResults: false });
        }
    }

    changeDrugType(option) {
        this.setState({
            drugType: option
        });

        if (option) {
            var selectedTrait = this.state.product.traits.find(p => p.name == option.value);
            if (selectedTrait) {
                this.setState({
                    drugName: selectedTrait.name,
                    formCode: selectedTrait.formCode,
                    strength: selectedTrait.strength,
                    quantity: selectedTrait.quantity
                });

                this.getProductDetail(selectedTrait.name, true);
            }
        }
    }

    applyFilters(data) {
        var locationChange = (this.state.filters && this.state.filters.location) != data.location;
        this.setState({ filters: data, showFilters: false }, function () {
            if (locationChange) {
                this.getProductDetail(this.state.drugName, true);
            }
            else {
                this.setState({ selectedDeliveryOption: 1/*local*/ });
                this.filterResults(1/*local*/, data);
            }
        });
    }

    getAvailableForms() {
        const uniqueForms = [];
        this.state.product.traits.map(t => {
            if (uniqueForms.findIndex(x => x.value == t.formCode) === -1) {
                uniqueForms.push({ value: t.formCode, label: t.formDesc });
            }
        });

        // rest selected value
        if (uniqueForms.length > 0) {
            if (!this.state.form || uniqueForms.findIndex(x => x.value == this.state.form.value) === -1) {
                this.setState({ form: uniqueForms[0] });
            }
        } else if (this.state.form) {
            this.setState({ form: '' });
        }

        return uniqueForms;
    }

    getAvailableStrengths() {
        const uniqueStrs = [];
        if (this.state.form) {
            this.state.product.traits.map(t => {
                if (t.formCode == this.state.form.value
                    && uniqueStrs.findIndex(x => x.value == t.strength) === -1) {
                    uniqueStrs.push({ value: t.strength, label: t.strength });
                }
            });
        }

        // rest selected value
        if (uniqueStrs.length > 0) {
            if (!this.state.strength || uniqueStrs.findIndex(x => x.value == this.state.strength.value) === -1) {
                this.setState({ strength: uniqueStrs[0] });
            }
        } else if (this.state.strength) {
            this.setState({ strength: '' });
        }

        return uniqueStrs;
    }

    getAvailableQuantities() {
        const uniqueQty = [];
        if (this.state.form && this.state.strength) {
            this.state.product.traits.map(t => {
                if (t.formCode == this.state.form.value
                    && t.strength == this.state.strength.value
                    && uniqueQty.findIndex(x => x.value == t.quantity) === -1) {
                    uniqueQty.push({ value: t.quantity, label: t.quantity });
                }
            });
        }

        // rest selected value
        if (uniqueQty.length > 0) {
            if (!this.state.quantity || uniqueQty.findIndex(x => x.value == this.state.quantity.value) === -1) {
                this.setState({ quantity: uniqueQty[0] });
            }
        } else if (this.state.quantity) {
            this.setState({ quantity: '' });
        }

        return uniqueQty;
    }

    getAvailableDrugTypes() {
        const uniqueTypes = [];

        this.state.product.traits.map(t => {
            if (uniqueTypes.findIndex(x => x.value == t.name) === -1) {
                uniqueTypes.push({ value: t.name, label: t.name + ` (${t.brand ? "Brand" : "Generic"})`, type: (t.brand ? "Brand" : "Generic") });
            }
        });

        return uniqueTypes;
    }

    filterResults(selectedOption, filters) {

        filters = filters || this.state.filters;

        var product = this.state.product;
        if (product && selectedOption) {

            var results = [];

            if (selectedOption == 1) {
                results = product.pharmacies;

                if (filters && filters.filters) {
                    var options = filters.filters;
                    //exclude all rows where filter value not true (eg. 'deliveryService')
                    results = results.filter(x => options.findIndex(y => x[y.value] == false) == -1)
                }
            }
            else if (selectedOption == 2) {
                results = product.mailOrderResults;
            }
            else if (selectedOption == 3) {
                results = product.specialtyResults;
            }

            this.setState({ selectedDeliveryOption: selectedOption, results: results, hasMoreResults: results.length > this.state.listCount });
        }
    }

    displayResults() {
        const { product, results, coupon, couponPrice, listCount } = this.state;

        return (
            <table className="table">
                <colgroup>
                    <col />
                    <col width="120px" />
                    <col width="160px" />
                </colgroup>
                <thead>
                </thead>
                <tbody>
                    {
                        couponPrice ? (
                            <tr className="coupon-row" onClick={() => window.open(coupon.url, "_blank")}>
                                <td colSpan="2"><h6>Confirm your eligibility for this low price</h6></td>
                                <td>
                                    <h2>
                                        <NumberFormat value={couponPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </h2>
                                </td>
                            </tr>) : null
                    }
                    {
                        results && results.length > 0 ?
                            results.slice(0, listCount).map((item, index) => {
                                return (
                                    <tr key={index} className={index == 0 ? "first-row" : undefined}>
                                        <td>
                                            <h6>{item.pharmacyName}</h6>
                                            <p>{item.addr}<br />
                                                {item.city}, {item.state} {item.zip}
                                            </p>
                                            {item.distance ? <span>Distance: {item.distance} miles</span> : undefined}
                                        </td>
                                        <td>
                                            {item.estimatedCashPrice ?
                                                <p>
                                                    <NumberFormat value={item.estimatedCashPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={' Retail'} />
                                                </p> : undefined}
                                            <div className="info-text margin-top-10">
                                                {item.deliveryService ? <p>Delivery</p> : undefined}
                                                {item.driveUpWindow ? <p>Drive-Thru</p> : undefined}
                                                {item.walkInClinic ? <p>Clinical Staff</p> : undefined}
                                            </div>
                                        </td>
                                        <td>
                                            <h2>
                                                <NumberFormat value={item.memberCopay} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            </h2>
                                            <p className="info-text">Est. Member Price</p>
                                            {item.estimatedSavings ?
                                                <div className="savings">
                                                    <img src="/dist/img/coin.svg" width="24px" />
                                                    <div>
                                                        Your savings
                                                        <h5>
                                                            <NumberFormat value={item.estimatedSavings} displayType={'text'} thousandSeparator={true} prefix={'$'} /> ({Math.round(item.estimatedSavings / item.estimatedCashPrice * 100)}%)
                                                        </h5>
                                                    </div>
                                                </div> : undefined}
                                        </td>
                                    </tr>
                                )
                            }) : undefined
                    }
                </tbody>
            </table>
        )
    }

    render() {
        const { drugName, member, product, isLoading, form, quantity, strength,
            showFilters, filters, drugType, hasMoreResults, listCount, coupon,
            results, deliveryOptions, selectedDeliveryOption } = this.state;

        const SingleValue = ({ children, ...props }) => (
            <components.SingleValue {...props}>{props.data.type}</components.SingleValue>
        );

        var mapCetner = {};

        const markers = isLoading ? [] : results && results.slice(0, listCount).map((item, index) => {
            var info = <div>
                <h6>{item.pharmacyName}</h6>
                <p>{item.addr + ' ' + item.zip}</p>
                <h6>Member price: ${item.memberCopay}</h6>
            </div>
            //var icon = { url: "https://mt.googleapis.com/vt/icon/name=icons/spotlight/spotlight-poi.png&scale=1" };
            return { id: item.ncpdp, icon: undefined, lat: item.latitude, lng: item.longitude, info: info, title: item.pharmacyName };
        });

        if (filters && filters.location) {
            var icon = { url: "https://mt.google.com/vt/icon?name=icons/spotlight/spotlight-waypoint-blue.png" };
            markers.unshift({ id: 999999, icon: icon, lat: filters.location.lat, lng: filters.location.lng, title: "You are here" });
            mapCetner = { lat: filters.location.lat, lng: filters.location.lng };
        }
        else if (member && member.primaryAddress) {
            var icon = { url: "https://mt.google.com/vt/icon?name=icons/spotlight/spotlight-waypoint-blue.png" };
            markers.unshift({ id: member.membershipId, icon: icon, lat: member.primaryAddress.latitude, lng: member.primaryAddress.longitude, title: "You are here" });
            mapCetner = { lat: member.primaryAddress.latitude, lng: member.primaryAddress.longitude };
        }
        else if (markers && markers.length > 0) {
            mapCetner = { lat: markers[0].lat, lng: markers[0].lng };
        }
        return (
            <div className="searchdetail">
                <div className="row">
                    <div className="col-md-12">
                        <SearchBar onSelect={this.goToProduct} selectedOption={{ label: drugName, value: drugName }} />
                    </div>
                </div>
                <div className="row display-flex results">
                    <div className="col-lg-6 left-col">
                        <div className="margin-bottom-20 padding-box">
                            <h2>{drugName}</h2>
                            {this.getAvailableDrugTypes().length > 1 ?
                                <div className="flex-row drug-type-row">
                                    <div></div>
                                    <div className="form-inline">
                                        <label>Drug Type:</label>
                                        <Select
                                            className="drug-type-select"
                                            classNamePrefix="select"
                                            options={this.getAvailableDrugTypes()}
                                            components={{ SingleValue }}
                                            value={drugType}
                                            placeholder="Drug type"
                                            isSearchable={false}
                                            onChange={this.changeDrugType}
                                        />
                                    </div>
                                </div> : ''
                            }
                        </div>
                        <div className="padding-box">
                            <div>
                                <div className="flex-row margin-bottom-20">
                                    {quantity ?
                                        <h6 className="label">{quantity.label} {form.label}, {strength.label}</h6>
                                        : undefined}
                                </div>

                                <div className="filters form-inline">
                                    <Select
                                        className="select"
                                        classNamePrefix="select"
                                        options={this.getAvailableForms()}
                                        value={form}
                                        placeholder="form"
                                        isSearchable={false}
                                        onChange={(op) => this.setState({ form: op })}
                                    />
                                    <Select
                                        className="select"
                                        classNamePrefix="select"
                                        options={this.getAvailableStrengths()}
                                        value={strength}
                                        placeholder="strength"
                                        isSearchable={false}
                                        onChange={(op) => this.setState({ strength: op })}
                                    />
                                    <Select
                                        className="select"
                                        classNamePrefix="select"
                                        options={this.getAvailableQuantities()}
                                        value={quantity}
                                        placeholder="quantity"
                                        isSearchable={false}
                                        onChange={(op) => this.setState({ quantity: op })}
                                    />
                                    <button className="btn btn-default" onClick={(e) => this.getProductDetail(drugName, true)}>Refine</button>
                                </div>

                                <div className="header-row">
                                    <div className="flex-row">
                                        <h5>Pharmacies near {
                                            function () {
                                                if (filters && filters.useMyLocation) {
                                                    return 'your location';
                                                }
                                                else if (filters && filters.location) {
                                                    return filters.location.zip;
                                                }
                                                else if (member && member.primaryAddress) {
                                                    return member.primaryAddress.zip;
                                                }
                                            }()}
                                        </h5>
                                        <div className="apply-filter">
                                            <a href="#" onClick={(e) => { e.preventDefault(); this.setState({ showFilters: !showFilters }) }}>
                                                Change Location and Filters
                                                    <FontAwesomeIcon icon={showFilters ? faCaretUp : faCaretDown} color="#747474" />
                                            </a>
                                        </div>
                                    </div>
                                    <div>
                                        {filters && filters.filters && filters.filters.length > 0 && <p className="info-text margin-top-10">Filter: {filters.filters.map((d, i) => i < filters.filters.length - 1 ? d.label + "," : d.label)}</p>}
                                    </div>
                                    {showFilters ?
                                        <Filters onApplyFilters={this.applyFilters} filters={filters} />
                                        : ''}
                                </div>
                                {
                                    <div className="margin-bottom-10">
                                        <h6>Delivery Option</h6>
                                        <div>
                                            {
                                                deliveryOptions.map((item, index) => {
                                                    return (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <label>
                                                                <input
                                                                    name="deliveryOption"
                                                                    type="radio"
                                                                    checked={item.value == selectedDeliveryOption}
                                                                    onChange={(e) => this.filterResults(e.target.value)}
                                                                    value={item.value} /> {item.label}
                                                            </label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            isLoading ?
                                <Loader /> :
                                <div>
                                    {
                                        function () {
                                            if (!isLoading && coupon) {
                                                var imgUrl = coupon.couponImage || 'NULL.jpg';

                                                return (
                                                    <div className="card coupon-box d-lg-none">
                                                        <div className="card-body">
                                                            <a href={coupon.url} target="_blank">
                                                                <img src={appConfig.couponsStorageUrl + imgUrl} alt={coupon.programName} />
                                                            </a>
                                                        </div>
                                                    </div>)
                                            }
                                        }()
                                    }
                                    {this.displayResults()}
                                    <div className="flex-row padding-box margin-bottom-20">
                                        <div></div>
                                        <div className="apply-filter">
                                            {hasMoreResults
                                                && <a href="#" onClick={(e) => { e.preventDefault(); this.loadMoreResults() }}>
                                                    Show more
                                            </a>}
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="col-lg-6 right-col">
                        <div>
                            {
                                function () {
                                    if (!isLoading && coupon) {
                                        var imgUrl = coupon.couponImage || 'NULL.jpg';

                                        return (
                                            <div className="card coupon-box d-none d-lg-flex">
                                                <div className="card-body">
                                                    <a href={coupon.url} target="_blank">
                                                        <img src={appConfig.couponsStorageUrl + imgUrl} alt={coupon.programName} />
                                                    </a>
                                                </div>
                                            </div>)
                                    }
                                }()
                            }
                        </div>
                        <div className="map-container">
                            {
                                <Map center={mapCetner} zoom={10} markers={markers} />
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                </div>
            </div>
        );
    }
}
