import React from 'react';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Loader extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="text-center">
                <FontAwesomeIcon icon={faSpinner} size={this.props.size || "2x"} spin color={this.props.color || "#747474"} />
            </div>
        )
    }
}

export default Loader