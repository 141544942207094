import React, { Component } from 'react';

export class DrugHistory extends Component {
    static displayName = DrugHistory.name;

    render() {
        return (
            <div>
                <h1> Drug history </h1>
            </div>
        );
    }
}
