import React, { Component } from 'react';

export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
        super(props);

    }

    showCurrentYear() {
        return new Date().getFullYear();
    }

    render() {
        return (
            <React.Fragment>
                <footer className="main-footer">
                    <div className="pull-right hidden-xs">

                    </div>
                    <hr/>
                    <strong>Copyright &copy; {this.showCurrentYear()} <a href="#">NewAm Health</a>.</strong> All rights
        reserved.
        </footer>
            </React.Fragment>
        );
    }
}
