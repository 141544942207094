import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

fetch("/api/tenants/info")
    .then(res => res.json())
    .then(
        (result) => {
            window.tenant = result;

            ReactDOM.render(
                <BrowserRouter basename={baseUrl}>
                    <App />
                </BrowserRouter>,
                rootElement);

            registerServiceWorker.unregister();
        },
        (error) => {
            console.log(error);
        }
    )

