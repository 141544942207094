import React, { Component } from 'react';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

export class SearchBar extends React.Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        if (this.props.selectedOption) {
            this.state.selectedOption = this.props.selectedOption;
        }
    }

    state = {
        selectedOption: null,
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption })
        if (this.props.onSelect) {
            this.props.onSelect(selectedOption);
        }
    }
    loadProducts = inputValue =>
        new Promise(resolve => {
            if (inputValue) {
                fetch(`/api/products/search?startsWith=${inputValue}`)
                    .then(this.errorHandler)
                    .then(response => {
                        if (response.status == 200)
                            return response.json()

                        return null;
                    })
                    .then(data => {
                        if (data) {
                            resolve(data.map((p) => ({ label: p.name, value: p.name })));
                        }
                        else {
                            resolve([])
                        }
                    });
            }
            else {
                resolve([])
            }
        });


    errorHandler(response) {
        if (response.status != 200) {
            console.error(response);
        }
        return response;
    }

    render() {
        const { selectedOption } = this.state;
        const customStyles = {};
        //const customStyles = {
        //    control: (base, state) => ({
        //        ...base,
        //    }),

        //    option: (styles, { isFocused }) => {
        //        return {
        //            ...styles,
        //            cursor: 'pointer',
        //            backgroundColor: isFocused ? '#F3EFEF' : 'white',
        //            color: isFocused ? '#F0714E' : 'black',
        //        }
        //    },
        //}

        const DropdownIndicator = props => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <FontAwesomeIcon icon={faSearch} />
                    </components.DropdownIndicator>
                )
            )
        }

        return (
            <AsyncSelect
                cacheOptions
                loadOptions={this.loadProducts}
                className="searchbar"
                classNamePrefix="searchbar"
                value={selectedOption}
                onChange={this.handleChange}
                placeholder="Type your drug name (like Atorvastatin, Sildenafil, etc"
                openMenuOnClick={false}
                components={{ DropdownIndicator }}
                styles={customStyles}
                noOptionsMessage={() => null}
            />
        )
    }
}