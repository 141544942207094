import React, { Component, PureComponent } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, MarkerWithLabel, InfoWindow } from "react-google-maps"
import PropTypes from 'prop-types';
import { appConfig } from '../config';

const { compose, withProps, withStateHandlers, pure } = require("recompose");

export class Map extends React.PureComponent {
    static propTypes = {
        //onCenterChange: PropTypes.func,
        //onZoomChange: PropTypes.func,
        //onBoundsChange: PropTypes.func,
        //onMarkerHover: PropTypes.func,
        //onChildClick: PropTypes.func,
        center: PropTypes.any,
        zoom: PropTypes.number,
        markers: PropTypes.any
    }

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        console.log("map render");
        //const Markers = this.props.markers &&
        //    this.props.markers
        //        .map((marker, index) => (
        //            <MarkerWithLabel
        //                key={marker.id}
        //                position={{ lat: marker.lat, lng: marker.lng }}
        //                labelStyle={{ backgroundColor: "yellow", fontSize: "32px", padding: "16px" }}>
        //                <div>{marker.label}</div>
        //            </MarkerWithLabel>
        //        ));

        const MarkerWithInfoWindow = compose(withStateHandlers(() => ({
            isOpen: false,
        }), {
            onToggleOpen: ({ isOpen }) => () => ({
                isOpen: !isOpen,
            })
        }))(props =>
            <Marker
                key={props.id}
                position={props.position}
                icon={props.icon}
                label={props.label}
                title={props.title}
                onClick={props.onToggleOpen}>
                {props.isOpen && props.info && <InfoWindow onCloseClick={props.onToggleOpen}>
                    {props.info}
                </InfoWindow>}
            </Marker>
        );

        const MapWithAMakredInfoWindow = compose(
            withScriptjs,
            withGoogleMap
        )(props =>
            <GoogleMap
                defaultZoom={this.props.zoom}
                defaultCenter={this.props.center}>
                {this.props.markers
                    && this.props.markers.map(marker => {
                        return (
                            <MarkerWithInfoWindow
                                key={marker.id}
                                position={{ lat: marker.lat, lng: marker.lng }}
                                label={marker.label}
                                icon={marker.icon}
                                title={marker.title}
                                info={marker.info} >
                            </MarkerWithInfoWindow>
                        )
                    })}
            </GoogleMap>
        );

        const url = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${appConfig.gmapKey}`;
        return (

            <MapWithAMakredInfoWindow
                isMarkerShown
                googleMapURL={url}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ width: `100%`, height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
        )
    }
}