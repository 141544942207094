import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Header } from './header';
import { Footer } from './footer';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div className="wrapper">
                <Header />
                <div className="content-wrapper">
                    <Container style={{ "minHeight": "75vh" }}>
                            {this.props.children}
                    </Container>
                    <Footer />
                </div>
              
            </div>
        );
    }
}
